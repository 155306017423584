$thumbnail-size: 250px;
$product-description-height: 70px;

#products,.featured-products,.product-accessories,.product-miniature  {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .product-miniature{
    margin-bottom: 1.5rem;
  }
  .product-thumbnail {
    display: block;
  }
  .thumbnail-container {
    position: relative;
    background: white;
    .product-thumbnail {
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        box-shadow: 0 0 15px 0 #eee;
        transition: .2s all linear;
      }
      &:hover{
        img{
          box-shadow: 0 0 15px 0 #d4d4d4;
        }
      }
    }
  }
  .products-section-title {
    margin-bottom: 1.5rem;
  }
  .product-category{
    display: block;
    font-size: 16px;
    color: $gray-light;
    margin: 10px 0;
  }
  .product-title {
    font-size: 20px;
    font-weight: 600;
    a {
      color: $brand-primary;
      text-decoration: none;
    }
  }
  .product-price-and-shipping {
    color: $gray-dark;
    font-weight: 400;
    font-size: 17px;
    .discount-product {
      display: none;
    }
  }
  .variant-links {
    position: relative;
    text-align: center;
    width: 100%;
    padding-top: 0.1875rem;
    min-height: 2.5rem;
  }
  .highlighted-informations {
    padding-top: 0.625rem;
    z-index: 2;
    text-align: center;
    .quick-view {
      color: $gray;
      font-size: $base-font-size;
      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    margin-bottom: 1.5rem;
  }

  .product-miniature {
    .product-flags {
      li.product-flag {
        min-width: 3.125rem;
        min-height: 1.875rem;
        font-weight: 600;
        text-align: center;
        &.online-only {
          top: 13.1rem;
        }
        &.new{
          position: absolute;
          left: 1.5rem;
          top: 0;
          min-width: 6rem;
          &:before{
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            lefT: 0;
            bottom: -12px;
            border-style: solid;
            border-width: 12px 3rem 0 3rem;
            border-color: $brand-primary transparent transparent transparent;
            // background: $brand-primary;
          }
        }
        &.pack{
          position: absolute;
          top: .5rem;
          right: .5rem;
        }
        &.discount{
          position: absolute;
          bottom: 1.5rem;
          left: 1.5rem;
        }
      }
    }
  }
  .comments_note {
    text-align: center;
    color: $gray;
  }
  .regular-price {
    text-decoration: line-through;
  }
  .count {
    color: $gray;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }
  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@include media-breakpoint-down(sm) {
  #products,.featured-products,.product-accessories  {
    .thumbnail-container {
      .product-description{
      }
    }
  }
}
