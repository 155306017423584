.carousel {
  .direction {
    z-index: auto;
  }
  .carousel-inner {
    margin: 0;
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    .caption {
      position: absolute;
      bottom: 0;
      top:0;
      left: 5vw;
      display:flex;
      align-items: center;
      align-content: center;
      color: white;
      max-width: 50%;
      flex-wrap: wrap;
      .caption-title{
        font-size: 3rem;
        margin: 0 0 10px;
      }
      .caption-description p {
        font-size: 1.4rem;
        line-height: 1.2;
        color: white;
      }
    }
    figure {
      margin: 0;
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      &::before {
        content: "";
      }
      i {
        font-size: 3.125rem;
        color: white;
      }
      &:hover {
        i {
          color: #eee;
        }
      }
    }
    .icon-prev {
      left: 1rem;
    }
    .icon-next {
      right: 2rem;
    }
    &.left,
    &.right {
      background: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .carousel {
    .carousel-inner {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 2rem);
        left: 10%;
      }
      .caption {
        .display-1 {
          font-size: 2rem;
        }
      }
      .caption-description {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .carousel {
    .carousel-item {
      .caption {
        bottom: calc(50% - 1.5rem);
      }
      .caption {
        .display-1 {
          font-size: 1.5rem;
        }
      }
    }
    .carousel-control {
      .icon-prev, .icon-next {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}
