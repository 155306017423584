// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  $active-background: darken($background, 10%);
  $active-border: darken($border, 12%);

  color: $color;
  background-color: $background;
  border-color: $border;

  @include hover {
    color: $color;
    background-color: $active-background;
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: $active-background;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
    // Remove the gradient for the pressed/active state
    background-image: none;
    @include box-shadow($btn-active-box-shadow);
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
    }
    @include hover {
      background-color: $background;
    }
  }
}

@mixin button-outline-variant($color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  @include hover {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }

  &:focus,
  &.focus {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: $color;
        border-color: $color;

    &:hover,
    &:focus,
    &.focus {
      color: #fff;
      background-color: darken($color, 17%);
          border-color: darken($color, 25%);
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    @include hover {
      border-color: lighten($color, 20%);
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  @include border-radius($border-radius);
}
