@mixin search-box {
  form {
    position: relative;
    display: flex;
    input[type=text] {
      &:focus {
        outline: none;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-secondary;
        }
      }
      border: none;
      padding: 10px 2vmin;
      min-width: 255px;
      color: $gray;
      border-bottom: 1px solid $brand-primary;
    }
    button[type=submit] {
      background: none;
      border: none;
      color: $brand-primary;
      cursor: pointer;
      transition: .2s all linear;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
