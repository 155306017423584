.block_newsletter {
  font-size: $font-size-sm;
  margin-bottom: 0.625rem;
  color: #fff;
  .newsletter-form{
    display: flex;
    align-items: center;
  }
  form {
    width: 100%;
    .input-wrapper {
      overflow: hidden;
      display: flex;
    }
    input[type=email] {
      width: 100%;
      padding: 11px;
      color: #fff;
      border: 2px solid #fff;
      background: transparent;
      &::placeholder{
        color: #fff;
      }
      &:focus {
        outline: 0;
      }
    }
    input {
      height: 42px;
      box-shadow: none;
    }
  }
}
#footer {
  .block_newsletter {
    p {
      padding-top: $small-space;
    }
  }
}
#left-column {
  .block_newsletter {
    @include box-shadow;
    margin-bottom: 1.563rem;
    padding: 1.563rem 1.25rem;
  }
}
