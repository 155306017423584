.search-widget {
  margin: .5vmin;
  @include search-box;
}
.header-top {
  .search-widget {
    form {
      input[type="text"] {
        min-width: 250px;
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.page-not-found {
  .search-widget {
    form {
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      float: none;
      form {
        margin: 0 auto;
        input[type="text"] {
          min-width: inherit;
          background: white;
        }
      }
    }
  }
}
