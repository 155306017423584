.brand{
	padding: 15px 0;
	border-bottom: 1px solid $brand-primary;
	&-image{
		display: flex;
		align-items: center;
		height: 100%;
		img{
			width: 100%;
			height: auto;
		}
	}
	&-info{
		display: flex;
		align-content: center;
		height: 100%;
		flex-wrap: wrap;
	}
	&-title{
		font-size: 27px;
		&::after{
			content:'';
			display: block;
			width: 100px;
			height: 40px;
			margin: 15px 0;
			background: url(../img/emblem.png) no-repeat;
			background-size: contain;
			background-position: center;
			border: none;
		}
	}
	&-text{
		line-height: 1.7;
		font-style: italic;
	}
}