@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@font-face{
	font-family: 'Catamaran';
	src: url('../fonts/catamaran/catamaran-regular.ttf') format('truetype'),
	     url('../fonts/catamaran/catamaran-regular.woff') format('woff'),
	     url('../fonts/catamaran/catamaran-regular.woff2') format('woff2');
	font-weight: 400;
  font-style: normal;
}
@font-face{
	font-family: 'Catamaran';
	src: url('../fonts/catamaran/catamaran-medium.ttf') format('truetype'),
	     url('../fonts/catamaran/catamaran-medium.woff') format('woff'),
	     url('../fonts/catamaran/catamaran-medium.woff2') format('woff2');
	font-weight: 500;
  font-style: normal;
}
@font-face{
	font-family: 'Catamaran';
	src: url('../fonts/catamaran/catamaran-bold.ttf') format('truetype'),
	     url('../fonts/catamaran/catamaran-bold.woff') format('woff'),
	     url('../fonts/catamaran/catamaran-bold.woff2') format('woff2');
	font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  src: url(../fonts/MaterialIcons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
