#custom-text {
  background: white;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: 3.125rem 3.125rem;
  text-align: center;
  h2 {
    text-transform: uppercase;
    color: $gray-darker;
    font-size: 1.563rem;
    font-weight: 700;
  }
  p {
    color: $gray-darker;
    font-weight: 400;
    font-size: 1.1em;
    .dark {
      color: $gray;
      font-weight: 400;
    }
  }
}
.page-content.page-cms{
  background: white;
  padding: 0 0 3rem;
  text-align: justify;
  .col-xs-12{
    margin: 15px 0;
  }
  .abou-txt-center{
    display: flex;
    align-items: center;
    align-content: center;
  }
  .cms-box{
    img{
      max-width: 100%;
    }
  }
}

.cms-about{
  .col-xs-12{
    margin: 0!important;
  }
  &-info{
    display: flex;
    height: 100%;
    padding: 3vmin;
    align-content: center;
    flex-wrap: wrap;
    .home-title{
      width: 100%;
    }
  }
  &-image{
    height: 100%;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-desc{
    text-align: left;
    line-height: 1.7;
  }
  &-quotation{
    display: flex;
    align-content: center;
    position: relative;
    width: 100vw;
    min-height: 40vh;
    padding: 3vmin 0;
    left: 50%;
    right: 50%;
    margin-left: calc(-50vw - 10px);
    margin-right: calc(-50vw - 10px);
    color: #fff;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    background: url(../img/about/about-background.jpg) no-repeat;
    background-size: cover;
    b{
      display: block;
      width: 100%;
      font-family: 'Great Vibes', cursive;
      font-size: 57px;
      font-weight: 400;
      margin: 0 0 15px;
      line-height: 1;
    }
    span{
      font-size: 27px;
    }
  }
  &-mision{
    margin: 7vmin 0;
    box-shadow: 0 0 2px 2px #f2f2f2;
  }
}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
