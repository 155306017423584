.cart{
  &-top{
    display: inline-block;
    padding: 0 .5vmin;
  }
  &-preview{
    display: inline-block;
    position: relative;
    padding-right: 15px;
    &-icon{
      display: block;
      width: 20px;
      height: 20px;
      background: url(../img/cart-icon.svg) no-repeat;
      background-size: cover;
      transition: .2s all linear;
    }
    &:hover{
      .cart-preview-icon{
        transform: translateY(-3px);
      }
    }
    .cart-products-count{
      position: absolute;
      font-size: 12px;
      bottom: -10px;
      right: 5px;
      background: #fff;
    }
  }
}
.cart-grid {
  margin-bottom: 1rem;
}
.cart-items {
  margin-bottom: 0;
}
.cart-item {
  padding: 1rem 0;
}
.card-block {
  padding: 1.25rem 1.25rem 1.25rem;
  &.checkout {
    padding-bottom: 1.75rem;
    padding-top: 0;
    margin-top: 1rem;
  }

  &.cart-summary-totals {
    padding: 0 1rem 1rem;
    .cart-summary-line {
      padding: .5rem .2rem 0;

      &:last-child {
        margin-bottom: 0
      }
      &.cart-total {
        background-color: $gray-lighter;
        padding: .5rem;
        .label,
        .value {
          font-weight: bold;
        }
        .label {
          font-size: 1rem;
        }
        .value {
          font-size: 1.125rem;
        }
      }
      span {
        &.sub {
          text-align: left;
          font-size: .88rem;
          float: none;
        }
      }
    }
  }
}

.cart-summary-line {
  @include clearfix;
  clear: both;
  margin-bottom: .315rem;
  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
    color: $gray-dark;
  }
  .value {
    color: $gray-darker;
    float: right;
  }
  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}
/** CART BODY **/
.cart-grid-body {
  a.label {
    &:hover {
      color: $brand-primary;
    }
  }
  .card-block {
    padding: 1rem;
    h1 {
      margin-bottom: 0;
    }
  }
  .cart-overview {
    padding: 1rem;
  }
  margin-bottom: 0.75rem;
}
/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    margin-bottom: 0;
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;

    .cart-summary-line {
      .label {
        font-size: 1rem;
        color: $gray;

        .code {
          color: $warning-second;
          cursor: pointer;
        }
      }
    }
  }
  .cart-summary-line {
    &.cart-subtotal-products {
      .label,
      .value {
        font-weight: 600;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}

.block-promo {
  margin-bottom: 1rem;

  .promo-code {
    padding: 1rem;
    .alert-danger {
      position: relative;
      margin-top: 1.25rem;
      background: $brand-danger;
      color: white;
      display: none;
      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color:  $brand-danger;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    form {
      text-align: center;
    }
  }
  .promo-input {
    color: $gray-darker;
    border: $gray 1px solid;
    height: 2.5rem;
    text-indent: 0.625rem;
    width: 50%;
    margin-right: -.24rem;
    + button {
      margin-top: -4px;
      margin-left: -1px;
      text-transform: capitalize;
      border: 2px solid $brand-primary;
    }
  }
  .cart-summary-line .label,
  .promo-name {
    color: $brand-warning;
    font-weight: 600;
    margin-bottom: 0;
    a {
      font-weight: normal;
      color: $gray-darker;
      display: inline-block;
    }
  }
  .promo-name {
    .cart-summary-line {
      .label {
        font-weight: normal;
        color: $gray-dark;
      }
      span {
        color: $warning-second;
      }
    }
    .material-icons {
      font-size: 1.2rem;
      margin-left: .4rem;
    }
  }
  .promo-code-button {
    display: block;
    text-align: center;
    padding: 0 0 1rem;

    &.with-discounts {
      display: none;
    }

    &.cancel-promo {
      color: $brand-primary;
      margin-bottom: 0;
      &[aria-expanded="true"] {
        display: block;
        padding: 0;
        margin-top: 1rem;
      }
    }
  }
  &.promo-highlighted {
    padding: 0 1rem;
    margin-bottom: 0;
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
}
/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}
/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }
    > .out-of-stock {
      color: red;
    }
    > .available {
      color: $brand-success;
    }
    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $gray;
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    color: $gray-darker;
    line-height: 36px;
    .remove-from-cart {
      color: $gray-darker;
      display: inline-block;
      margin-top: 0.3125rem;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }
  .cart-item {
    border-bottom: 1px solid $gray-light;
    &:last-child {
      border-bottom: 0;
    }
  }
  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }
    .no-items {
      padding: 1rem;
      display: inline-block;
    }
  }
  .product-line-grid-left {
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }
    .price {
      width: 100%;
    }
  }
}
