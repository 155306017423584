.contact-rich {
  color: $gray;
  margin-bottom: 2rem;
  word-wrap: break-word;
  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 2rem;
  }
  .block {
    height: auto;
    overflow: hidden;
    .icon {
      float: left;
      width: 3.5rem;
      i {
        font-size: 2rem;
      }
    }
    .data {
      color: $gray-darker;
      font-size: $font-size-sm;
      width: auto;
      overflow: hidden;
      &.email{
        padding-top: 0.375rem;
      }
    }
    a[href^="mailto:"] {
      clear: both;
      display: block;
      margin-top: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: .815rem;
      }
    }
  }
}
.contact-form {
  background: white;
  padding: 1rem;
  color: $gray;
  width: 100%;
  h3 {
    text-transform: uppercase;
    color: $gray-darker;
  }
}

.block-contact{
  color: $brand-primary;
  &-content{
    padding: 15px;
  }
  &__logo{
    display: block;
    margin:0 0 15px;
    img{
      max-width: 100%;
    }
  }
  &__company{
    font-size: 22px;
    color: $brand-primary;
    margin: 0 0 15px;
  }
  &__item{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    line-height: 2;
    i{
      margin-right: 15px;
    }
    .block-contact__subitem{
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}