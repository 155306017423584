.block-contact {
  color: $gray;
  .block-contact-title{
    color:$gray-darker;
  }
}
.linklist {
  .blockcms-title a {
    color:$gray-darker;
  }
}
.account-list a {
  color: $gray;
  &:hover {
    color: $brand-primary;
  }
}
.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}
.block-social{
  display:inline-block;
  ul{
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    a {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 2.4;
      font-family:'FontAwesome'!important;
      font-size: 22px!important;
      text-align: center;
      color: $brand-primary!important;
      text-shadow: 0px 0px 5px #fff;
      transition: .2s all linear;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}
.block-social-footer{
  padding: 15px;
  li {
    display: inline-block;
    a {
      display: block;
      margin-right: 10px;
      line-height: 2.4;
      font-family:'FontAwesome'!important;
      font-size: 22px!important;
      text-align: center;
      color: $brand-primary!important;
      transition: .2s all linear;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}
.social-title{
  font-size: 22px;
  font-weight:600;
  color: $brand-primary;
  margin: 30px 0 15px;
}

.facebook {
  & a::before{
    content:"\f082";
  }
  &.icon-gray {
    &:hover {
    }
  }
}
.twitter {
  & a::before{
    content:"\f099";
  }
  &.icon-gray {
    &:hover {
    }
  }
}
.rss {
  & a::before{
    content:'\f09e';
  }
}
.youtube {
  & a::before{
    content:'\f167';
  }
}
.googleplus {
  & a::before{
    content:"\f0d5";
  }
  &.icon-gray {
    &:hover {
    }
  }
}
.pinterest {
  & a::before{
    content:"\f0d2";
  }
  &.icon-gray {
    &:hover {
    }
  }
}
.vimeo {
  & a::before{
    content:'\f27d';
  }
}
.instagram {
  & a::before{
    content:'\f16d';
  }
}
#block_myaccount_infos{
  .myaccount-title a{
    color:$gray-darker;
  }
}
.footer-container {
  margin-top: $medium-space;
  padding-top: $medium-space;
  overflow: hidden;
  li {
    margin-bottom: 0.3125rem;
  }
  li a {
    color: $brand-primary;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      color: $brand-secondary;
    }
  }
}
.footer-title{
  font-size: 22px;
  font-weight:600;
  color: $brand-primary;
  margin: 0 0 15px;
}
.links {
  .collapse {
    display: inherit;
  }
}
.footer-copyright{
  margin: 30px 0 0;
  .container{
    padding: 15px;
    border-top:1px solid $brand-primary;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
  }
  .footer-container {
    box-shadow: none;
    margin-top: 0;
    .links {
      .h3 {
        line-height: 1.5;
        font-size: 1rem;
      }
      ul {
        margin-bottom: 0;
        > li {
          padding: 0.625rem;
          border-bottom: 1px solid white;
          a {
            color: $gray-darker;
          }
        }
      }
    }
  }
  .links {
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
    .title {
      padding: 0.625rem;
      border-bottom: 1px solid $gray-lighter;
      cursor: pointer;
      .collapse-icons .remove {
        display: none;
      }
    }
    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}
@include media-breakpoint-down(md) {
  #footer .block-social {
    width: 100%;
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}
