@import "partials/_variables";
@import "bootstrap/bootstrap-flex";
@import "bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min";
@import "partials/_mixins";
@import "../../../_libraries/font-awesome/css/font-awesome";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/brands";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/newsletter";
/*** HEADER ***/
#header {
  background: white;
  color: $gray;
  .logo {
    max-width: 170px;
  }
  .menu {
    display: inline-block;
    > ul {
      > li {
        display: inline-block;
      }
    }
  }
  .header-nav {
    margin-bottom: 10px;
    #menu-icon {
      vertical-align: middle;
      cursor: pointer;
      margin: 0 15px 0 0;
      .material-icons {
        line-height: 50px;
      }
    }
    .mobile{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }
    .user-info {
      white-space: nowrap;
      height: 3rem;
      padding: 15px 15px 0;
    }
    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }
    .cart-preview {
      .shopping-cart {
        vertical-align: middle;
        color: $gray;
      }
      .body {
        display: none;
      }
    }
    .blockcart {
      height: 3rem;
      padding: 15px 15px 0;
      text-align: center;
      white-space: nowrap;
    }
    #_desktop_contact_link{
      display: inline-block;
      #contact-link {
        margin-top: 0.9375rem;
      }
    }
    .search-widget {
      margin-top: 0.2rem;
    }
    .material-icons {
      line-height: inherit;
      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }
  .header-top {
    > .container {
      position: relative;
    }
    padding-bottom: 0.25rem;
    .menu {
      padding-left: 15px;
      margin-bottom: 0.375rem;
    }
    .position-static {
      position: static;
    }
    a[data-depth="0"] {
      color: $gray;
      text-transform: uppercase;
    }
  }
  .header-menu{
    background: $brand-primary;
    color: #fff;
  }
  .top-menu-link {
    margin-left: $medium-space;
  }
}
.header-flex{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.language{
  display: inline-block;
  margin: 10px 2rem 0;
}
.popover {
  font-family: inherit;
}
/*** WRAPPER ***/
#wrapper {
  padding-top: 1.563rem;
  .banner {
    margin-bottom: 1.5rem;
    display: block;
  }
  .breadcrumb {
    background: transparent;
    padding: 0 15px;
    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: inline;
      &::after {
        content: ">";
        color: $gray;
        margin: 0.2125rem;
      }
      &:last-child {
        content: ">";
        color: $gray;
        margin: 0;
        &::after {
          content: "";
        }
      }
      a {
        color: $gray;
      }
    }
  }
}
/*** MAIN ***/
#main {
  .page-header {
  }
  .page-content {
    margin-bottom: 1.563rem;
    h6 {
      margin-bottom: 1.125rem;
    }
    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .page-footer {
    margin-bottom: 1.563rem;
  }
}
#notifications {
  ul {
    margin-bottom: 0;
  }
}

.home{
  &-title{
    color: $brand-primary;
    &__text{
      font-size:2rem;
    }
    &__hr{
      display: inline-block;
      width: 100px;
      height: 40px;
      margin: 15px auto;
      background: url(../img/emblem.png) no-repeat;
      background-size: contain;
      background-position: center;
      border:none;
    }
    &.--white{
      color: #fff;
      .home-title__hr{
        background: url(../img/emblem-white.png) no-repeat;
      }
    }
  }
}
.desc{
  margin: 15px 0;
  font-size: 16px;
  line-height: 1.4;
}
.recommended-products{
  padding:5vh 0
}

.about{
  &-us{
    color: #fff;
    padding: 10vh 0;
    margin: 0 -15px;
    background: url(../img/about-us.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }
  .desc{
    color: #fff;
  }
}

.home-categories{
  padding: 10vh 0;
  .categories{
    &-col{
      padding: 0;
    }
    &-item{
      padding: 0;
      &-box{
        display: block;
        position: relative;
        &::before{
          content:'';
          display: block;
          width:100%;
          height: 50%;
          position: absolute;
          bottom:0;
          left:0;
          background: rgba(231,56,39,0);
          background: -moz-linear-gradient(top, rgba(231,56,39,0) 0%, rgba(0,0,0,1) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(231,56,39,0)), color-stop(100%, rgba(0,0,0,1)));
          background: -webkit-linear-gradient(top, rgba(231,56,39,0) 0%, rgba(0,0,0,1) 100%);
          background: -o-linear-gradient(top, rgba(231,56,39,0) 0%, rgba(0,0,0,1) 100%);
          background: -ms-linear-gradient(top, rgba(231,56,39,0) 0%, rgba(0,0,0,1) 100%);
          background: linear-gradient(to bottom, rgba(231,56,39,0) 0%, rgba(0,0,0,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e73827', endColorstr='#000000', GradientType=0 );
          transition: .2s all linear;
        }
        &:hover::before{
          height:60%;
        }
      }
      &__image{
        width: 100%;
        height: auto;
      }
      &__title{
        position: absolute;
        bottom: 2rem;
        left: 0;
        display:flex;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        color: #fff;
        font-weight: 400;
        font-size: 1.6rem;
        &::before{
          content:'';
          display: inline-block;
          width: 23px;
          height: 23px;
          margin: 0 10px;
          background: url(../img/left-herb.png) no-repeat;
          background-size: contain;
          background-position: center;
        }
        &::after{
          content:'';
          display: inline-block;
          width: 23px;
          height: 23px;
          margin: 0 10px;
          background: url(../img/right-herb.png) no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
}

.new-products{
  padding: 5vh 0;
  margin: 0 -15px;
  background: url(../img/nowosci.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  .product-category, .product-title a, .product-price-and-shipping{
    color: #fff;
  }
  .product-miniature .thumbnail-container .product-thumbnail img{
    box-shadow: none;
  }
}

.blog-home-title{
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  &__text{
    font-size: 2rem;
    color: $brand-primary;
  }
  &__hr{
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    background: url(../img/emblem-blog.png) no-repeat;
    background-size: contain;
    background-position: left;
    &::before{
      content:'';
      display:block;
      position: absolute;
      top: 14px;
      width:100%;
      height: 2px;
      background: $brand-primary;
    }
  }
}
.subpage-wrapper{
  width:100%;
  padding:25px 15px;
}
/*** FOOTER ***/
#footer {
  padding-top: 2.5rem;
  background: url(../img/footer.png) no-repeat;
  background-size: cover;
  background-position: top;
}

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  #header {
    .header-nav {
      max-height: inherit;
      .search-widget {
       float: none;
       width: 20.63rem;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header {
    .logo {
      width: auto;
    }
    .header-top {
      .search-widget {
        min-width: inherit;
      }
    }
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 15.63rem;
    margin: 0 auto 0.9375rem;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
}
@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    .header-nav {
      background: white;
      margin-bottom: 0.625rem;
      color: $gray-darker;
      .top-logo {
        padding-top: 11px;
        a {
          img {
            max-height: 50px;
            max-width: 100%;
          }
        }
      }
      .right-nav {
        flex-direction: column;
      }
    }
    .header-top {
      a[data-depth="0"] {
        color: $gray-darker;
      }
      .search-widget {
        width: 100%;
      }
    }
    &.is-open {
      background-color: white;
      .header-top {
        background-color: white;
      }
    }
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
  #footer {
    padding-top: 0.5rem;
  }
}

input[type=number] {
  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@media(min-width: 992px){
  .header-flex{
    align-items: center;
    justify-content: flex-end;
    flex-wrap:none;
  }
}